.container {
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes float {
  0% {
    margin-bottom: 0em;
    box-shadow: 0 3px 5px rgb(0 0 0 / 0.4);
  }

  50% {
    margin-bottom: 2em;
    box-shadow: 0 20px 30px rgb(0 0 0 / 0.2);
  }

  100% {
    margin-bottom: 0em;
    box-shadow: 0 3px 5px rgb(0 0 0 / 0.4);
  }
}

.card {
  font-family: sans-serif;
  text-align: right;
  background: #24384a;
  width: 25rem;
  height: 35rem;
  color: #8cb1c7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  animation: float 5s ease infinite;
  border-radius: 10px;
}

.name {
  font-weight: bold;
  font-size: 1.5em;
}

.links {
  color: #23a6d5;
  font-size: 1.3em;
}

.colorize {
  filter: invert(70%) sepia(45%) saturate(4034%) hue-rotate(160deg)
    brightness(89%) contrast(87%);
}
/* unvisited link */
a:link {
  color: #23a6d5;
  text-decoration: none;
}

/* visited link */
a:visited {
  color: #23a6d5;
  text-decoration: none;
}

.sub {
  text-align: left;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 1em;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.head {
  font-family: "Poppins";
  font-weight: 900;
  font-size: 9em;
  margin-top: -30px;
  margin-right: 20px;
  color: #283f5a;
}

.email {
  font-size: 0.8em;
}

.divider {
  height: 5px;
}

#root {
  height: 100%;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

body {
  /* background: rgb(36, 56, 74); */
  background: linear-gradient(-45deg, #eeb052, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 30s ease infinite;
  height: 100vh;
  overflow: hidden;
}
